

import axios from "@/axios.js";
import { reject, resolve } from 'core-js/fn/promise';


export default{


  GetAllAgentPatient({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("API/AgentPatientRate/GetAllAgentPatient")
        .then((response) => {
          commit('SET_AgentPatient', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  AddAgentPatientRate({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("API/AgentPatientRate/AddAgentPatientRate", item)
        .then((response) => {
        //  commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  ViewAgentPatientRateHistory({ commit }, AgentPatientUserID) {
      return new Promise((resolve, reject) => {
      axios.get("API/AgentPatientRate/ViewAgentPatientRateHistory?AgentPatientUserID="+ AgentPatientUserID)
        .then((response) => {
          commit('SET_AgentPatient', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

};
