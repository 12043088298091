export default {

  ADD_ITEM(state, item) {
    state.AgentPatientRates.unshift(item);
  },

  SET_AgentPatient(state, AgentPatientRates) {
    state.AgentPatientRates = AgentPatientRates
  },
};
