

import  state  from "./moduleAgentPatientRateState";
import  mutations  from "./moduleAgentPatientRateMutations";
import  actions from "./moduleAgentPatientRateAction";
import getters from "./moduleAgentPatientRateGetters";


export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
